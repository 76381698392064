/**
 * get Current Lang
 */
export function getCurrentLang() {
  return localStorage.getItem('currentLang');
}

/**
 * set Current Lang
 * @param {String} locale
 */
export function setCurrentLang(locale) {
  localStorage.setItem('currentLang', locale);
}
