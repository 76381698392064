import { configRoute } from 'multiLangRoute/routeHelpers';

export const routes = {
  home: configRoute('home'),
  creditForm: configRoute('credit'),
  contactForm: configRoute('contact'),
  appointmentForm: configRoute('rdv'),
  agencies: configRoute('agencies'),
  simulators: configRoute('simulators'),
  questionnaire: configRoute('questionnaire'),
  faq: configRoute('faq'),
  accompagnement: configRoute('accompagnement'),
  startup: configRoute('startup'),
  startupServices: configRoute('startupServices'),
  startupcycle: configRoute('startupcycle'),
  ae: configRoute('ae'),
  tpe: configRoute('tpe'),
  mediatheque: configRoute('mediatheque'),
  porteurprojet: configRoute('porteurprojet'),
  underway: configRoute('underway'),
  mentions: configRoute('mentions'),
  conditions: configRoute('conditions'),
};
