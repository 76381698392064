import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'multiLangRoute/intl';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export function LocalizedRouter({
  children,
  RouterComponent,
  appStrings,
  languages,
  defaultLanguage,
  appUrl,
  history,
}) {
  return (
    <RouterComponent history={history}>
      <Route path="/:lang([a-z]{2})">
        {({ match, location }) => {
          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const params = match ? match.params : {};
          const selectedLang = languages.find((lg) => lg.key === params.lang);
          const lang = selectedLang ? params.lang : defaultLanguage;

          /**
           * If language is not in route path, redirect to language root
           */
          const { pathname } = location;
          if (!pathname.includes(`/${lang}/`)) {
            return <Redirect to={`/${lang}/`} />;
          }

          /**
           * Return Intl provider with default language set
           */
          return (
            <IntlProvider locale={lang} messages={appStrings[lang]}>
              <Helmet>
                <html lang={lang} dir={selectedLang.dir} />
                <meta
                  property="og:url"
                  content={`${appUrl}${pathname.substring(1)}`}
                />
              </Helmet>
              {children}
            </IntlProvider>
          );
        }}
      </Route>
    </RouterComponent>
  );
}

LocalizedRouter.propTypes = {
  children: PropTypes.node.isRequired,
  RouterComponent: PropTypes.elementType.isRequired,
  appStrings: PropTypes.objectOf(PropTypes.object).isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  appUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
