import produce from 'immer';
import { apiActionTypes } from 'api/apiUtils';
import { defaultLanguage } from 'i18nConfig';
import { HIDE_ERROR_MSG, UPDATE_CURRENT_LANG } from './common.types';

const initialState = {
  msgVisible: false,
  message: '',
  messageCode: null,
  apiCallsInProgress: 0,
  currentLang: defaultLanguage,
};

const commonReducer = produce((draft, action) => {
  const { requestTag, errorTag, successTag } = apiActionTypes;

  if (action.type.endsWith(errorTag)) {
    draft.msgVisible = true;
    draft.message = action.payload.errorMessage;
    draft.messageCode = action.payload.errorCode;
  } else if (
    action.type.endsWith(successTag) ||
    action.type.endsWith(requestTag) ||
    action.type === HIDE_ERROR_MSG
  ) {
    draft.msgVisible = initialState.msgVisible;
    draft.message = initialState.message;
    draft.messageCode = initialState.messageCode;
  }

  if (action.type.endsWith(requestTag)) {
    draft.apiCallsInProgress += 1;
  } else if (
    action.type.endsWith(successTag) ||
    action.type.endsWith(errorTag)
  ) {
    draft.apiCallsInProgress -= 1;
  }

  if (action.type === UPDATE_CURRENT_LANG) {
    draft.currentLang = action.lang;
  }
}, initialState);

export default commonReducer;
