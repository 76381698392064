import { configRouteSeo } from 'multiLangRoute/routeHelpers';
import { routes } from '../index';

export default {
  [routes.home.route]: '/',
  [routes.home.title]: 'Accueil',
  [routes.home.seo]: configRouteSeo(
    'Accueil',
    "la banque populaire a le plaisir de participer au programme national d'Accompagnement et de financement des petites entreprises et porteurs de projets",
    'Chaabi Intelak QARAWI, BP START-UP, Cartes bancaires, Offres packagées, Banque à distance, Auto-entrepreneur'
  ),
  [routes.creditForm.route]: '/credit',
  [routes.creditForm.title]: 'Crédit',
  [routes.creditForm.seo]: configRouteSeo(
    'Crédit',
    'Demandez un crédit',
    'Credit, Al Moukawil Dati COVID-19'
  ),
  [routes.contactForm.route]: '/contact',
  [routes.contactForm.title]: 'Contact',
  [routes.contactForm.seo]: configRouteSeo(
    'Contact',
    'Partagez vos remarques avec nous',
    'Contact, Al Moukawil Dati COVID-19'
  ),
  [routes.appointmentForm.route]: '/rendez-vous',
  [routes.appointmentForm.title]: 'Rendez-vous',
  [routes.appointmentForm.seo]: configRouteSeo(
    'Rendez-vous',
    'Prenez un rendez-vous',
    'Rendez-vous, Al Moukawil Dati COVID-19'
  ),
  [routes.agencies.route]: '/agences',
  [routes.agencies.title]: 'Agences',
  [routes.agencies.seo]: configRouteSeo(
    'Agences',
    'Trouver votre agence, nous sommes plus proches que vous ne le pensez, pour être au plus près de nos clients et les servir au mieux nos conseillers restent joignables par téléphone',
    'agence, rendez-vous, demande, localiser'
  ),
  [routes.simulators.route]: '/simulateurs',
  [routes.simulators.title]: 'Simulateurs',
  [routes.simulators.seo]: configRouteSeo(
    'Simulateurs',
    'Besoin d’un crédit, faites vos simulations en ligne',
    'INTELAK INVEST, INTELAK AL QARAOUI, BP START-UP'
  ),
  [routes.questionnaire.route]: '/questionnaire',
  [routes.questionnaire.title]: 'Questionnaire',
  [routes.questionnaire.seo]: configRouteSeo(
    'Questionnaire',
    'La Banque met à la disposition des entreprises sélectionnées un dispositif de financement approprié, à travers l’equity ou un financement bancaire selon le niveau de maturité des projets',
    'Questionnaire, Auto-entrepreneur, Artisan, Commerçant, Coopérative, entreprise, exportateur, exploitation agricole'
  ),
  [routes.faq.route]: '/faq',
  [routes.faq.title]: 'Foire aux questions',
  [routes.faq.seo]: configRouteSeo(
    'Foire aux questions',
    'Nous sommes là pour vous aider ! Voici les questions les plus fréquentes',
    'FAQ, Foire aux questions, aider, répondons'
  ),
  [routes.accompagnement.route]: '/accompagnement',
  [routes.accompagnement.title]: 'Accompagnement',
  [routes.accompagnement.seo]: configRouteSeo(
    'Accompagnement',
    "Banque Populaire pour les Start-Up, le programme qui permettra aux Start-Up d'accéder à de nouveaux marchés pour développer leurs activités",
    'Accompagnement, Startup, Financement, TPE'
  ),
  [routes.startup.route]: '/startup',
  [routes.startup.title]: 'START-UP',
  [routes.startup.seo]: configRouteSeo(
    'START-UP',
    'banque populaire START-UP pour vous aider à grandir',
    'Cycle de vie, Ecosystème Start-Up, Start-Up, Financement, TPE'
  ),
  [routes.startupServices.route]: '/offrestartup',
  [routes.startupServices.title]: 'Offre START-UP',
  [routes.startupServices.seo]: configRouteSeo(
    'Offre START-UP',
    'La Banque met à la disposition des entreprises sélectionnées un dispositif de financement approprié, à travers l’equity ou un financement bancaire selon le niveau de maturité des projets',
    'Acquérir un local, Financer les dépenses, Acheter des serveurs, Recruter de nouvelles ressources'
  ),
  [routes.startupcycle.route]: '/cycle-de-vie-startup',
  [routes.startupcycle.title]: 'Cycle de vie START-UP',
  [routes.startupcycle.seo]: configRouteSeo(
    'Cycle de vie START-UP',
    'banque populaire START-UP pour vous aider à grandir',
    'Ecosystème, IDÉATION, CRÉATION, AMORÇAGE, EXPANSION'
  ),
  [routes.ae.route]: '/auto-entrepreneur',
  [routes.ae.title]: 'Auto-entrepreneur',
  [routes.ae.seo]: configRouteSeo(
    'Auto-entrepreneur',
    "Banque Populaire pour les Auto-entrepreneurs, le programme qui permettra aux Auto-entrepreneurs d'accéder à de nouveaux marchés pour développer leurs activités",
    'Accompagnement, Start-Up, Financement, Auto-entrepreneur, AE'
  ),
  [routes.tpe.route]: '/tpe',
  [routes.tpe.title]: 'Très Petite Entreprise',
  [routes.tpe.seo]: configRouteSeo(
    'Très Petite Entreprise',
    "Le programme qui permettrait aux TPE d'accéder à de nouveaux marchés pour développer leurs activités",
    'TPE, Très Petite Entreprise, START UP, Accompagnement, Financement'
  ),
  [routes.porteurprojet.route]: '/porteurdeprojet',
  [routes.porteurprojet.title]: 'Porteur de projet',
  [routes.underway.route]: '/contenu-en-construction',
  [routes.underway.title]: 'Contenu en construction',
  [routes.mediatheque.route]: '/mediatheque',
  [routes.mediatheque.title]: 'Médiathèque',
  [routes.mediatheque.seo]: configRouteSeo(
    'Médiathèque',
    "Bienvenue sur l'espace Médiathèque",
    'PHOTOS, VIDÉOS, Brochures, Guides'
  ),
  [routes.mentions.route]: '/mentions',
  [routes.mentions.title]: 'Mentions légales',
  [routes.mentions.seo]: configRouteSeo(
    'Mentions légales',
    "Les présentes mentions légales ont pour objet de vous informer sur vos droits et vos obligations relatifs à l'utilisation du site internet Al Moukawil Chaabi",
    'Mentions légales, droits, utilisation, Droit de propriété intellectuelle, Crédits photos'
  ),
  [routes.conditions.route]: '/conditions',
  [routes.conditions.title]: 'Conditions générales',
  [routes.conditions.seo]: configRouteSeo(
    'Conditions générales',
    "Les présentes mentions légales ont pour objet de vous informer sur vos droits et vos obligations relatifs à l'utilisation du site internet Al Moukawil Chaabi",
    'Conditions générales, Droit de propriété intellectuelle, Crédits photos'
  ),
};
