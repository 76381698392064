import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import rootSaga from './rootSaga';

// config middlewares
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

// config REDUX DEVTOOLS EXTENSION
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

// config redux persist
const persistConfig = {
  key: 'store',
  storage,
  whitelist: ['common', 'resources'],
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(reducers)
);

// create the store
const store = createStore(
  combineReducers(reducers), // persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

// register sagas
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
