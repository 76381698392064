import {
  GET_APP_RESOURCES,
  GET_FAQ,
  GET_MEDIAS,
  GET_LINKS,
  build,
} from 'rest/config';
import { APP_RESOURCES, FAQ, MEDIAS, LINKS } from './resources.types';

export const getAppResources = () => ({
  type: APP_RESOURCES,
  request: {
    METHOD: GET_APP_RESOURCES.type,
    ENDPOINT: GET_APP_RESOURCES.url,
    PAYLOAD: {},
    HEADERS: {},
  },
});

export const getFaq = (themeId) => ({
  type: FAQ,
  request: {
    METHOD: GET_FAQ.type,
    ENDPOINT: build(GET_FAQ.url, [{ key: 'themeId', value: themeId }]),
  },
});

export const getMedias = (categoryId) => ({
  type: MEDIAS,
  request: {
    METHOD: GET_MEDIAS.type,
    ENDPOINT: build(GET_MEDIAS.url, [{ key: 'categoryId', value: categoryId }]),
  },
});

export const getLinks = (typeId) => ({
  type: LINKS,
  request: {
    METHOD: GET_LINKS.type,
    ENDPOINT: build(GET_LINKS.url, [{ key: 'type', value: typeId }]),
  },
});
