/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'multiLangRoute/intl';
import { setCurrentLang } from 'multiLangRoute/currentLang';

export default function LangSwitchWrapper({ onSwitch, children }) {
  const { locale } = useIntl();

  /**
   * update the active lang in the store
   */
  useEffect(() => {
    setCurrentLang(locale);
    if (onSwitch) onSwitch(locale);
  }, [locale, onSwitch]);

  return <>{children}</>;
}

LangSwitchWrapper.propTypes = {
  onSwitch: PropTypes.func,
  children: PropTypes.node.isRequired,
};
