import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getAppResources } from 'store/reducers/resources/resources.actions';
import Button from 'components/Button';
import logo from 'assets/medias/images/logo.svg';
import Link from 'navigation/Link';
import { LanguageSwitcher, appStrings, appLanguage } from 'i18nConfig';
import { useIntl } from 'multiLangRoute/intl';
import LangSwitchWrapper from 'multiLangRoute/LangSwitchWrapper';
import useTranslation from 'multiLangRoute/useTranslation';
import { getRouteTitleKey } from 'multiLangRoute/routeHelpers';
import { routes } from 'routes';

export default function Header() {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  const toggleSideMenu = useCallback(() => {
    setIsSideMenuOpen((state) => !state);
  }, []);

  const toggleSearch = useCallback(() => {
    setIsSearchOpen((state) => !state);
  }, []);

  const handleSwitchLang = useCallback(() => {
    dispatch(getAppResources());
  }, [dispatch]);

  const handleSearchValue = useCallback((e) => {
    setSearchVal(e.target.value);
  }, []);

  const handleSubmitSearch = (e) => {
    if (e.type === 'keypress' && e.key !== 'Enter') return;

    const pagesSeoKey = Object.keys(messages).filter((key) =>
      key.includes('.seo')
    );
    const pagesSeoContent = pagesSeoKey.map((key) => ({
      key: key.replace('seo', 'route'),
      content: `${messages[key].title} ${messages[key].description} ${messages[key].keywords}`,
    }));
    const routesSearchPosition = pagesSeoContent.map((obj) => ({
      ...obj,
      pos: obj.content.toUpperCase().indexOf(searchVal.trim().toUpperCase()),
    }));
    const routesSearchFound = routesSearchPosition.filter(
      ({ pos }) => pos >= 0
    );
    const routesSearchSorted = routesSearchFound.sort((a, b) => a.pos - b.pos);
    setSearchResult(routesSearchSorted);
  };

  return (
    <header>
      <div className="header-bottom">
        <div className="header__blocleft">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className={`menu o-nav ${isSideMenuOpen && 'is-inViewport'}`}>
            <ul>
              <li>
                <Link path={routes.home.route}>{t(routes.home.title)}</Link>
              </li>
              <li>
                <Link path={routes.startup.route}>
                  {t(routes.startup.title)}
                </Link>
              </li>
              <li>
                <Link path={routes.underway.route}>
                  {t(routes.porteurprojet.title)}
                </Link>
              </li>
              <li>
                <Link path={routes.ae.route}>{t(routes.ae.title)}</Link>
              </li>
              <li>
                <Link path={routes.tpe.route}>{t(routes.tpe.title)}</Link>
              </li>
              <li>
                <Link path={routes.mediatheque.route}>
                  {t(routes.mediatheque.title)}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="header__blocright">
          <div className={`search__form ${isSearchOpen ? 'active' : ''}`}>
            <div className="container">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Que recherchez-vous ?"
                  value={searchVal}
                  onChange={handleSearchValue}
                  onKeyPress={handleSubmitSearch}
                />
                <button type="button" onClick={handleSubmitSearch}>
                  ok
                </button>
              </div>
              <div className="result">
                <p className="font-italic mb-3">
                  {searchResult.length} Resultat(s)
                </p>
                <ul>
                  {searchResult.map(({ key }) => (
                    <li key={key}>
                      <Link onClick={toggleSearch} path={key}>
                        {t(getRouteTitleKey(key))}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <button type="button" className="close" onClick={toggleSearch}>
              &nbsp;
            </button>
          </div>
          <div className="header__bottom-bloc">
            <div className="col__menu--mobile">
              <Button
                onClick={toggleSideMenu}
                className={`border-0 bg-transparent c-nav__icon ${
                  isSideMenuOpen ? 'is-active' : ''
                }`}
              >
                <span> </span>
              </Button>
            </div>
            <button
              type="button"
              className="border-top-0 border-bottom-0 bg-transparent searh"
              onClick={toggleSearch}
            >
              search
            </button>
            <LangSwitchWrapper onSwitch={handleSwitchLang}>
              <LanguageSwitcher
                appStrings={appStrings}
                appLanguage={appLanguage}
              />
            </LangSwitchWrapper>
            <div className="espace-client">
              <Link path={routes.contactForm.route}>Nous contacter</Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
