import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import useTranslation from 'multiLangRoute/useTranslation';
import defaultCover from 'assets/medias/images/autoentrepeneur.png';

const APP_URL = process.env.REACT_APP_URL;
const GA_ANALYTICS_KEY = process.env.REACT_APP_GA_ANALYTICS_KEY;

export default function SEO({ url, title, description, keywords, cover }) {
  const { t } = useTranslation();

  const APP_NAME = t('APP_NAME');

  if (GA_ANALYTICS_KEY) {
    ReactGA.initialize(GA_ANALYTICS_KEY);
    ReactGA.pageview(url);
  }

  return (
    <Helmet>
      <title>
        {title} | {APP_NAME}
      </title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="image" content={cover || defaultCover} />
      <meta property="og:title" content={title || APP_NAME} />
      <meta property="og:description" content={description} />
      {url && <meta property="og:url" content={`${APP_URL}${url}`} />}
      <meta property="og:image" content={cover || defaultCover} />
    </Helmet>
  );
}

SEO.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  cover: PropTypes.string,
};
