import common from './common/common.reducer';
import resources from './resources/resources.reducer';
import agencies from './agencies/agencies.reducer';

const reducers = {
  common,
  resources,
  agencies,
};

export default reducers;
