import React from 'react';
import Simulez from 'assets/medias/images/Simulez.svg';
import Credit from 'assets/medias/images/Credit.svg';
import Agence from 'assets/medias/images/Agence.svg';
import RDV from 'assets/medias/images/RDV.svg';
import useTranslation from 'multiLangRoute/useTranslation';
import { routes } from 'routes';
import Link from 'navigation/Link';

export const PRE_FIX = 'OurServices';

export default function OurServices() {
  const { t } = useTranslation(PRE_FIX);

  return (
    <section className="nos-services">
      <div className="container">
        <h2 className="h2">{t('title')}</h2>
        <div className="services text-center">
          <div className="row">
            <div className="col-md-3">
              <div className="icon">
                <img src={Simulez} alt="Simulez" />
              </div>
              <Link path={routes.simulators.route} className="service__title">
                {t('simulation')}
              </Link>
            </div>
            <div className="col-md-3">
              <div className="icon">
                <img src={Credit} alt="Credit" />
              </div>
              <Link path={routes.creditForm.route} className="service__title">
                {t('creditRequest')}
              </Link>
            </div>
            <div className="col-md-3">
              <div className="icon">
                <img src={Agence} alt="Agence" />
              </div>
              <Link path={routes.agencies.route} className="service__title">
                {t('agencyNetwork')}
              </Link>
            </div>
            <div className="col-md-3">
              <div className="icon">
                <img src={RDV} alt="rendez vous" />
              </div>
              <Link
                path={routes.appointmentForm.route}
                className="service__title"
              >
                {t('rdvRequest')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
