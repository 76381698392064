import React from 'react';

export default function Error() {
  return (
    <div className="page">
      <div className="content">
        <div className="content">
          <div className="page_404">
            <div className="container">
              <div className="row">
                <div className="col-md-12 content-404">
                  <div className="content">
                    <div className="text">
                      <h3>Oups</h3>
                      <p>Une erreur est survenue</p>
                      <a className="btn btn-orange" href="/">
                        Retour à la page d’accueil
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
