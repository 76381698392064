import { PRE_FIX } from '../index';

export default {
  [`${PRE_FIX}:title`]: 'Des services de proximité pour donner vie à vos projets ',
  [`${PRE_FIX}:simulation`]: 'Simulation de crédit ',
  [`${PRE_FIX}:creditRequest`]: 'Demande de crédit ',
  [`${PRE_FIX}:agencyNetwork`]: 'Réseau de proximité ',
  [`${PRE_FIX}:rdvRequest`]: 'Prise de Rendez-vous',
  [`${PRE_FIX}:simulate`]: 'Simulez votre crédit',
  [`${PRE_FIX}:credit`]: 'Demandez votre  crédit',
  [`${PRE_FIX}:agency`]: 'Trouvez votre agence',
  [`${PRE_FIX}:rdv`]: 'Prenez rendez-vous',
};
