import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { getAppResources } from 'store/reducers/resources/resources.actions';
import RouterLoader from 'components/RouterLoader';
import { routes } from 'routes';
import history from 'utils/history';
import { appStrings, appLanguage, defaultLanguage } from 'i18nConfig';
import { LocalizedRouter, LocalizedSwitch } from 'multiLangRoute';
import ErrorBoundary from 'features/ErrorBoundary';
import AppLayout from './AppLayout';

import './app.css';

const Home = lazy(() => import('./pages/Home'));
const CreditForm = lazy(() => import('./pages/CreditForm'));
const ContactForm = lazy(() => import('./pages/ContactForm'));
const AppointmentForm = lazy(() => import('./pages/AppointmentForm'));
const Agencies = lazy(() => import('./pages/Agencies'));
const SimulatorsPage = lazy(() => import('./pages/SimulatorsPage'));
const Faq = lazy(() => import('./pages/FAQ'));
const AccompagnementLandingpage = lazy(() =>
  import('./pages/Autoentrepreneur')
);
const Startup = lazy(() => import('./pages/Startup'));
const Questionnaire = lazy(() => import('./pages/Questionnaire'));
const StartupServices = lazy(() => import('./pages/StartupServices'));
const StartupCycle = lazy(() => import('./pages/StartupCycle'));
const Mediatheque = lazy(() => import('./pages/Mediatheque'));
const NotFound = lazy(() => import('./pages/NotFound'));
const UnderWay = lazy(() => import('./pages/UnderWay'));
const AutoEntrepreneur = lazy(() => import('./pages/Autoentrepreneur'));
const TPE = lazy(() => import('./pages/TPE'));
const Mentions = lazy(() => import('./pages/Mentions'));
const Conditions = lazy(() => import('./pages/Conditions'));

export default function App() {
  const dispatch = useDispatch();

  // componentDidMount
  useEffect(() => {
    dispatch(getAppResources());
  }, [dispatch]);

  return (
    <LocalizedRouter
      RouterComponent={Router}
      defaultLanguage={defaultLanguage}
      languages={appLanguage}
      appStrings={appStrings}
      appUrl={process.env.REACT_APP_URL}
      history={history}
    >
      <AppLayout>
        <ErrorBoundary>
          <Suspense fallback={<RouterLoader />}>
            <LocalizedSwitch>
              <Route exact path={routes.home.route}>
                <Home />
              </Route>
              <Route exact path={routes.creditForm.route}>
                <CreditForm />
              </Route>
              <Route exact path={routes.contactForm.route}>
                <ContactForm />
              </Route>
              <Route exact path={routes.appointmentForm.route}>
                <AppointmentForm />
              </Route>
              <Route exact path={routes.agencies.route}>
                <Agencies />
              </Route>
              <Route exact path={routes.simulators.route}>
                <SimulatorsPage />
              </Route>
              <Route path={routes.accompagnement.route}>
                <AccompagnementLandingpage />
              </Route>
              <Route exact path={routes.startup.route}>
                <Startup />
              </Route>
              <Route exact path={routes.startupServices.route}>
                <StartupServices />
              </Route>
              <Route exact path={routes.questionnaire.route}>
                <Questionnaire />
              </Route>
              <Route exact path={routes.faq.route}>
                <Faq />
              </Route>
              <Route exact path={routes.startupcycle.route}>
                <StartupCycle />
              </Route>
              <Route exact path={routes.mediatheque.route}>
                <Mediatheque />
              </Route>
              <Route exact path={routes.ae.route}>
                <AutoEntrepreneur />
              </Route>
              <Route exact path={routes.tpe.route}>
                <TPE />
              </Route>
              <Route exact path={routes.mentions.route}>
                <Mentions />
              </Route>
              <Route exact path={routes.conditions.route}>
                <Conditions />
              </Route>
              <Route exact path={routes.underway.route}>
                <UnderWay />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </LocalizedSwitch>
          </Suspense>
        </ErrorBoundary>
      </AppLayout>
    </LocalizedRouter>
  );
}
