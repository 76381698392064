import produce from 'immer';
import { GET_AGENCIES_SUCCESS, GET_HUBS_SUCCESS } from './agencies.types';

const initialState = {
  agencies: [
    {
      key: 1,
      longitude: -7.4580333,
      latitude: 33.5722678,
      address: '',
      phoneNumber: '',
    },
  ],
  hubs: [],
};

const agenciesReducer = produce((draft, action) => {
  switch (action.type) {
    case GET_AGENCIES_SUCCESS:
      draft.agencies = action.payload.agencies;
      break;
    case GET_HUBS_SUCCESS:
      draft.hubs = action.payload.cities;
      break;
    default:
      break;
  }
}, initialState);

export default agenciesReducer;
