import produce from 'immer';
import {
  APP_RESOURCES_SUCCESS,
  FAQ_SUCCESS,
  FAQ_REQUEST,
  FAQ_ERROR,
  MEDIAS_SUCCESS,
  MEDIAS_REQUEST,
  MEDIAS_ERROR,
  LINKS_SUCCESS,
  LINKS_REQUEST,
  LINKS_ERROR,
} from './resources.types';

const initialState = {
  activities: [],
  financingTypes: [],
  financingSubTypes: [],
  cities: [],
  subjects: [],
  faq: { faqData: [], isFaqLoading: false },
  themes: [],
  mediaCategories: [],
  medias: { mediasData: [], isMediasLoading: false },
  links: { linksData: [], isLinksLoading: false },
};

const resourcesReducer = produce((draft, action) => {
  switch (action.type) {
    case APP_RESOURCES_SUCCESS:
      draft.activities = action.payload.activities;
      draft.financingSubTypes = action.payload.financingSubTypes;
      draft.financingTypes = action.payload.financingTypes;
      draft.subjects = action.payload.subjects;
      draft.cities = action.payload.cities;
      draft.themes = action.payload.themes;
      draft.mediaCategories = action.payload.mediaCategories;
      break;

    case FAQ_REQUEST:
      draft.faq.faqData = [];
      draft.faq.isFaqLoading = true;
      break;

    case FAQ_SUCCESS:
      draft.faq.faqData = action.payload.faq || [];
      draft.faq.isFaqLoading = false;
      break;

    case FAQ_ERROR:
      draft.faq.faqData = [];
      draft.faq.isFaqLoading = false;
      break;

    case MEDIAS_REQUEST:
      draft.medias.mediasData = [];
      draft.medias.isMediasLoading = true;
      break;

    case MEDIAS_SUCCESS:
      draft.medias.mediasData = action.payload.medias || [];
      draft.medias.isMediasLoading = false;
      break;

    case MEDIAS_ERROR:
      draft.medias.mediasData = [];
      draft.medias.isMediasLoading = false;
      break;

    case LINKS_REQUEST:
      draft.links.linksData = [];
      draft.links.isLinksLoading = true;
      break;

    case LINKS_SUCCESS:
      draft.links.linksData = action.payload.links || [];
      draft.links.isLinksLoading = false;
      break;

    case LINKS_ERROR:
      draft.links.linksData = [];
      draft.links.isLinksLoading = false;
      break;

    default:
      break;
  }
}, initialState);

export default resourcesReducer;
