import React, { useState } from 'react';
import { cookieStorage } from 'utils/cookieStorage';
import Link from 'navigation/Link';
import { routes } from 'routes';
import cookieIcon from 'assets/medias/images/cookie.svg';

export default function CookieConsent() {
  const consentPropertyName = 'amc_cookie_consent';
  const [accepted, isAccepted] = useState(
    !!cookieStorage.getItem(consentPropertyName)
  );

  function acceptCookies() {
    isAccepted(true);
    cookieStorage.setItem(consentPropertyName, true);
  }

  return (
    <div className={`cookies ${accepted ? 'd-none' : ''}`}>
      <div className="content">
        <div className="top">
          <img src={cookieIcon} alt="Préférences Cookies" />
          <h4 className="title">Préférences Cookies</h4>
        </div>
        <p>
          En poursuivant votre navigation sur ce site, vous acceptez
          l'utilisation de cookies afin de bénéficier des services et des offres
          adaptés à vos centres d’intérêts.
        </p>

        <div className="action">
          <Link path={routes.mentions.route} className="btn btn-transparent">
            En savoir plus
          </Link>
          <button
            type="button"
            className="btn btn-orange"
            onClick={acceptCookies}
          >
            J’accepte
          </button>
        </div>
      </div>
    </div>
  );
}
