export const build = (url, params) => {
  let s = url;
  params.forEach((prm) => {
    s = s.replace(`{${prm.key}}`, prm.value);
  });
  return s;
};

const BASE_URL =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
    ? process.env.REACT_APP_API_BASE_URL
    : window.BACKEND_URL;

const API_URL = `${BASE_URL}landingpage/api`;

// Controllers
const APP_CONTROLLER = `${API_URL}/fields/web/`;
const GET_FAQ_BY_THEME = 'themes/{themeId}/faq/';
const GET_HUBS_ALL = 'hubs/';

const FORMS_CONTROLLER = `${API_URL}/forms/`;

const AGENCIES_CONTROLLER = `${API_URL}/fields/web/hubs/{cityId}/agencies/`;

const STORAGE_CONTROLLER = `${API_URL}/buckets/`;
const GET_FILES_NAME_BY_BUCKET_URL = `${STORAGE_CONTROLLER}{bucket}/`;
const GET_FILE_URL = `${STORAGE_CONTROLLER}{bucket}/files/{fileId}/`;

const GET_MEDIAS_BY_CAT = 'categories/{categoryId}/medias/';

const GET_LINKS_BY_TYPE = 'links/{type}';

// Methods
export const GET_APP_RESOURCES = {
  url: APP_CONTROLLER,
  type: 'GET',
};

export const SEND_FORM = {
  url: FORMS_CONTROLLER,
  type: 'POST',
};

export const GET_AGENCIES = {
  url: AGENCIES_CONTROLLER,
  type: 'GET',
};

export const GET_FAQ = { url: APP_CONTROLLER + GET_FAQ_BY_THEME, type: 'GET' };

export const GET_HUBS = { url: APP_CONTROLLER + GET_HUBS_ALL, type: 'GET' };

export const GET_FILES_NAME_BY_BUCKET = {
  url: GET_FILES_NAME_BY_BUCKET_URL,
  type: 'GET',
};

export const GET_FILE = {
  url: GET_FILE_URL,
  type: 'GET',
};

export const GET_MEDIAS = {
  url: APP_CONTROLLER + GET_MEDIAS_BY_CAT,
  type: 'GET',
};

export const GET_LINKS = {
  url: APP_CONTROLLER + GET_LINKS_BY_TYPE,
  type: 'GET',
};
