import { useIntl } from 'multiLangRoute/intl';

/**
 * use Translation
 * @param {String} tag
 */
export default function useTranslation(tag) {
  const intl = useIntl();

  return {
    t: (msg) =>
      intl.formatMessage({
        id: `${tag ? `${tag}:` : ''}${msg}`,
      }),
  };
}
