/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'multiLangRoute/intl';
import { getMatchingRoute } from 'multiLangRoute/routeHelpers';
import history from 'utils/history';

export const LanguageSwitcher = ({ appStrings, appLanguage }) => {
  const { pathname } = useLocation();
  const { locale, messages } = useIntl();

  return (
    <div className="lang">
      <select
        onChange={(e) =>
          history.push(
            getMatchingRoute(pathname, messages, appStrings, e.target.value)
          )
        }
        value={locale}
      >
        {appLanguage.map((lang) => (
          <option key={lang.key} value={lang.key}>
            {lang.key.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
};

LanguageSwitcher.propTypes = {
  appStrings: PropTypes.objectOf(PropTypes.object).isRequired,
  appLanguage: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string.isRequired })
  ).isRequired,
};
