import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from 'navigation/Navigation';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SEO from 'components/SEO';
import FloatingMenu from 'components/FloatingMenu';
import { useIntl } from 'multiLangRoute/intl';
import { getCurrentPageSeo } from 'multiLangRoute/routeHelpers';
import CookieConsent from 'features/CookieConsent';

export default function AppLayout({ children }) {
  const { pathname } = useLocation();
  const { messages } = useIntl();

  const currentPageSeo = useMemo(() => getCurrentPageSeo(pathname, messages), [
    pathname,
    messages,
  ]);

  return (
    <>
      <Header />
      <FloatingMenu />
      {currentPageSeo && <SEO {...currentPageSeo} />}
      {process.env.NODE_ENV === 'development' && <Navigation />}
      {children}
      <CookieConsent />
      <Footer />
    </>
  );
}
