import React from 'react';
import Link from 'navigation/Link';
import useTranslation from 'multiLangRoute/useTranslation';
import { routes } from 'routes';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="footer-top">
          <div className="suiveznous">
            <h3>Suivez nous sur:</h3>
            <div className="social">
              <ul>
                <li>
                  <a
                    className="facebook"
                    href="https://fr-fr.facebook.com/PopulaireEtDigitale/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/bp_maroc?lang=fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    className="linkden"
                    href="https://www.linkedin.com/company/banque-populaire-maroc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/channel/UCOrSrjmPtk_z5ZByBSkubwA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    youtube
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="top">
          <ul>
            <li>
              <Link path={routes.mentions.route}>
                {t(routes.mentions.title)}
              </Link>
            </li>

            <li>
              <Link path={routes.conditions.route}>
                {t(routes.conditions.title)}
              </Link>
            </li>
          </ul>
        </div>
        <div className="bottom" style={{ color: '#b0b0b0' }}>
          ©2020 Tous droits réservés ALMOUKAWIL CHAABI
        </div>
      </div>
    </footer>
  );
}
