import { typeCreator } from 'utils/redux';
import { apiActionTypes } from 'api/apiUtils';

const { requestTag, successTag, errorTag } = apiActionTypes;

export const APP_RESOURCES = 'APP_RESOURCES';
export const APP_RESOURCES_REQUEST = typeCreator(APP_RESOURCES, requestTag);
export const APP_RESOURCES_SUCCESS = typeCreator(APP_RESOURCES, successTag);
export const APP_RESOURCES_ERROR = typeCreator(APP_RESOURCES, errorTag);

export const FAQ = 'FAQ';
export const FAQ_REQUEST = typeCreator(FAQ, requestTag);
export const FAQ_SUCCESS = typeCreator(FAQ, successTag);
export const FAQ_ERROR = typeCreator(FAQ, errorTag);

export const MEDIAS = 'MEDIAS';
export const MEDIAS_REQUEST = typeCreator(MEDIAS, requestTag);
export const MEDIAS_SUCCESS = typeCreator(MEDIAS, successTag);
export const MEDIAS_ERROR = typeCreator(MEDIAS, errorTag);

export const LINKS = 'LINKS';
export const LINKS_REQUEST = typeCreator(LINKS, requestTag);
export const LINKS_SUCCESS = typeCreator(LINKS, successTag);
export const LINKS_ERROR = typeCreator(LINKS, errorTag);
