/**
 * config route SEO
 * @param {String} title
 * @param {String} description
 * @param {String} keywords
 */
export function configRouteSeo(title, description, keywords) {
  return { title, description, keywords };
}

/**
 * get Route Title Key
 * @param {String} routeKey
 */
export function getRouteTitleKey(routeKey) {
  return routeKey.replace('route', 'title');
}

/**
 * config Route
 * @param {String} name
 */
export function configRoute(name) {
  return { route: `${name}.route`, title: `${name}.title`, seo: `${name}.seo` };
}

/**
 * get Matching Route
 * @param {String} pathname
 * @param {Object} messages
 * @param {Object} appStrings
 * @param {String} locale
 */
export function getMatchingRoute(pathname, messages, appStrings, locale) {
  /**
   * Get the key of the route the user is currently on
   */
  const route = pathname.substring(3); // remove local part '/en' from the pathname /en/contact
  const routeKey = Object.keys(messages).find(
    (key) => messages[key] === route || `${messages[key]}/` === route
  );

  /**
   * Find the matching route for the new language
   */
  const matchingRoute = appStrings[locale][routeKey];

  /**
   * Return localized route
   */
  return `/${locale}${matchingRoute}`;
}

/**
 * get Matching Title
 * @param {String} path
 * @param {Object} messages
 * @param {Object} appStrings
 * @param {String} locale
 */
export function getMatchingTitle(path, messages, appStrings, locale) {
  const routeKey = Object.keys(messages).find(
    (key) => messages[key] === path || `${messages[key]}/` === path
  );

  const routeTitleKey = getRouteTitleKey(routeKey || '');

  return appStrings[locale][routeTitleKey];
}

/**
 * get Current Page SEO
 * @param {String} pathname
 * @param {Object} messages
 */
export function getCurrentPageSeo(pathname, messages) {
  const route = pathname.substring(3); // remove local part '/en' from the pathname /en/contact
  const routeKey = Object.keys(messages).find(
    (key) => messages[key] === route || `${messages[key]}/` === route
  );

  if (!routeKey) return null;

  const routeSeoKey = routeKey.replace('route', 'seo');
  return messages[routeSeoKey];
}
