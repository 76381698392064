import { typeCreator } from 'utils/redux';
import { apiActionTypes } from 'api/apiUtils';

const { requestTag, successTag, errorTag } = apiActionTypes;

export const AGENCIES = 'AGENCIES';
export const GET_AGENCIES_REQUEST = typeCreator(AGENCIES, requestTag);
export const GET_AGENCIES_SUCCESS = typeCreator(AGENCIES, successTag);
export const GET_AGENCIES_ERROR = typeCreator(AGENCIES, errorTag);

export const HUBS = 'HUBS';
export const GET_HUBS_REQUEST = typeCreator(HUBS, requestTag);
export const GET_HUBS_SUCCESS = typeCreator(HUBS, successTag);
export const GET_HUBS_ERROR = typeCreator(HUBS, errorTag);
