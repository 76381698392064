import { all, takeLatest } from 'redux-saga/effects';
import { apiCall } from 'api/apiCall';
import {
  APP_RESOURCES,
  FAQ,
  MEDIAS,
  LINKS,
} from 'store/reducers/resources/resources.types';
import { AGENCIES, HUBS } from 'store/reducers/agencies/agencies.types';

export default function* rootSaga() {
  yield all([takeLatest(HUBS, apiCall)]);
  yield all([takeLatest(APP_RESOURCES, apiCall)]);
  yield all([takeLatest(AGENCIES, apiCall)]);
  yield all([takeLatest(FAQ, apiCall)]);
  yield all([takeLatest(MEDIAS, apiCall)]);
  yield all([takeLatest(LINKS, apiCall)]);
}
