/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

export default function Button({
  type,
  text,
  disabled,
  isLoading,
  onClick,
  ...props
}) {
  const btnType = type || 'button';

  return (
    <button
      className="btn btn-orange full-padding next-step"
      type={btnType}
      disabled={disabled || isLoading}
      {...(btnType === 'button' && !isLoading && { onClick })}
      {...props}
    >
      <span>{text}</span>
      {isLoading && (
        <div
          className="mx-1 spinner-border"
          style={{ width: '1rem', height: '1rem' }}
          role="status"
          aria-label="loading..."
        >
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};
