import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'multiLangRoute/intl';

export default function Link({ path, children, ...props }) {
  const { formatMessage, locale } = useIntl();

  return (
    <NavLink exact to={`/${locale}${formatMessage({ id: path })}`} {...props}>
      {children}
    </NavLink>
  );
}

Link.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
};
