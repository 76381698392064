import React from 'react';
import { routes } from 'routes';
import Link from 'navigation/Link';
import useTranslation from 'multiLangRoute/useTranslation';

export default function FloatingMenu() {
  const { t } = useTranslation('OurServices');

  return (
    <div className="sticky-rightbar">
      <ul>
        <li>
          <Link path={routes.simulators.route}>
            <i className="icon-simulation">&nbsp;</i>
            <span>{t('simulate')}</span>
          </Link>
        </li>
        <li>
          <Link path={routes.creditForm.route}>
            <i className="icon-credit">&nbsp;</i>
            <span>{t('credit')}</span>
          </Link>
        </li>
        <li>
          <Link path={routes.agencies.route}>
            <i className="icon-agence">&nbsp;</i>
            <span>{t('agency')}</span>
          </Link>
        </li>
        <li>
          <Link path={routes.appointmentForm.route}>
            <i className="icon-rendezvous">&nbsp;</i>
            <span>{t('rdv')}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
