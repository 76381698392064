import { configRouteSeo } from 'multiLangRoute/routeHelpers';
import { routes } from '../index';

export default {
  [routes.home.route]: '/',
  [routes.home.title]: 'الصفحة الرئيسية',
  [routes.home.seo]: configRouteSeo(
    'الصفحة الرئيسية',
    'يسر البنك الشعبي المشاركة في البرنامج الوطني لدعم وتمويل المشاريع الصغيرة وقادة المشاريع',
    'الشعبي إنتلك قيراوي ، ستارت أب ، بطاقات مصرفية ، عروض مجمعة ، خدمات مصرفية عن بعد ، رائد أعمال آلي'
  ),
  [routes.creditForm.route]: '/ائتمان',
  [routes.creditForm.title]: 'ائتمان',
  [routes.creditForm.seo]: configRouteSeo(
    'ائتمان',
    'التقدم بطلب للحصول على قرض',
    'المقاول الداتي COVID-19, ائتمان'
  ),
  [routes.contactForm.route]: '/اتصل',
  [routes.contactForm.title]: 'اتصل',
  [routes.contactForm.seo]: configRouteSeo(
    'اتصل',
    'شاركنا بتعليقاتك',
    'المقاول الداتي COVID-19, اتصل'
  ),
  [routes.appointmentForm.route]: '/موعد',
  [routes.appointmentForm.title]: 'موعد',
  [routes.appointmentForm.seo]: configRouteSeo(
    'موعد',
    'إحجز موعد',
    'المقاول الداتي COVID-19, إحجز موعد'
  ),
  [routes.agencies.route]: '/وكالات',
  [routes.agencies.title]: 'وكالات',
  [routes.agencies.seo]: configRouteSeo(
    'وكالات',
    'ابحث عن وكالتك ، فنحن أقرب مما تعتقد ، لنكون قريبين قدر الإمكان من عملائنا ولخدمتهم قدر الإمكان ، يمكن الاتصال بمستشارينا عبر الهاتف',
    'وكالة ، موعد ، طلب ، تحديد'
  ),
  [routes.simulators.route]: '/المحاكاة',
  [routes.simulators.title]: 'المحاكاة',
  [routes.simulators.seo]: configRouteSeo(
    'المحاكاة',
    'بحاجة إلى قرض ، قم بإجراء عمليات المحاكاة الخاصة بك على الإنترنت',
    'استثمار انطلاق, القروي انطلاق, المقاول الداتي'
  ),
  [routes.questionnaire.route]: '/questionnaire',
  [routes.questionnaire.title]: 'إستفتاء',
  [routes.questionnaire.seo]: configRouteSeo(
    'إستفتاء',
    'يوفر البنك للشركات المختارة آلية تمويل مناسبة ، من خلال تمويل أسهم أو تمويل بنكي حسب مستوى استحقاق المشاريع',
    'استبيان ، رائد أعمال ، حرفي ، تاجر ، تعاوني ، شركة ، مصدر ، مزرعة'
  ),
  [routes.faq.route]: '/faq',
  [routes.faq.title]: 'التعليمات',
  [routes.faq.seo]: configRouteSeo(
    'أسئلة مكررة',
    'نحن هنا لمساعدتك ! إليك الأسئلة التي نجيب عليها كثيرًا',
    'التعليمات ، الأسئلة المتداولة ، المساعدة ، دعنا نجيب'
  ),
  [routes.accompagnement.route]: '/accompagnement',
  [routes.accompagnement.title]: 'مرافقة',
  [routes.accompagnement.seo]: configRouteSeo(
    'مرافقة',
    'البنك الشعبي من أجل رواد الأعمال الآليين ، البرنامج الذي سيسمح للشركات الناشئة بالوصول إلى أسواق جديدة لتطوير أنشطتها.',
    'الدعم ، بدء التشغيل ، التمويل ، الأعمال الصغيرة جدًا'
  ),
  [routes.startup.route]: '/startup',
  [routes.startup.title]: 'شركة ناشئة',
  [routes.startup.seo]: configRouteSeo(
    'شركة ناشئة',
    'ستارت أب من البنك الشعبي لمساعدتك على النمو',
    'دورة الحياة ، بدء التشغيل ، التمويل ، أعمال صغيرة جدًا'
  ),
  [routes.startupServices.route]: '/offrestartup',
  [routes.startupServices.title]: 'عروض الشركات الناشئة',
  [routes.startupServices.seo]: configRouteSeo(
    'عروض الشركات الناشئة',
    'يوفر البنك للشركات المختارة آلية تمويل مناسبة ، من خلال تمويل أسهم أو تمويل بنكي حسب مستوى استحقاق المشاريع',
    'الحصول على أماكن العمل ، والنفقات المالية ، وشراء الخوادم ، وتجنيد موارد جديدة'
  ),
  [routes.startupcycle.route]: '/cycle-de-vie-startup',
  [routes.startupcycle.title]: 'دورة حياةالشركات الناشئة',
  [routes.startupcycle.seo]: configRouteSeo(
    'دورة حياةالشركات الناشئة',
    'ستارت أب من البنك الشعبي لمساعدتك على النمو',
    'الفكرة ، الخلق ، الشروع ، التوسع'
  ),
  [routes.ae.route]: '/auto-entrepreneur',
  [routes.ae.title]: 'مقاول ذاتي',
  [routes.ae.seo]: configRouteSeo(
    'مقاول ذاتي',
    'البنك الشعبي لصغار رواد الأعمال ، البرنامج الذي سيسمح للشركات الناشئة بالوصول إلى أسواق جديدة لتطوير أنشطتها',
    'الدعم ، بدء التشغيل ، التمويل ، الأعمال الصغيرة جدًا'
  ),
  [routes.tpe.route]: '/tpe',
  [routes.tpe.title]: 'شركة صغيرة جدًا',
  [routes.tpe.seo]: configRouteSeo(
    'شركة صغيرة جدًا',
    'البرنامج الذي سيسمح للشركات الناشئة بالوصول إلى أسواق جديدة لتطوير أنشطتها',
    'مشروع صغير جدا ، بدء ، دعم ، تمويل'
  ),
  [routes.porteurprojet.route]: '/porteurdeprojet',
  [routes.porteurprojet.title]: 'صاحب مشروع',
  [routes.underway.route]: '/contenu-en-construction',
  [routes.underway.title]: 'المحتوى قيد الإنشاء',
  [routes.mediatheque.route]: '/mediatheque',
  [routes.mediatheque.title]: 'مكتبة الوسائط',
  [routes.mediatheque.seo]: configRouteSeo(
    'مكتبة الوسائط',
    'مرحبا بكم في منطقة مكتبة الوسائط',
    'صور وفيديوهات وكتيبات'
  ),
  [routes.mentions.route]: '/mentions',
  [routes.mentions.title]: 'إشعار قانوني',
  [routes.mentions.seo]: configRouteSeo(
    'إشعار قانوني',
    'الغرض من هذه الإشعارات القانونية هو إطلاعك على حقوقك والتزاماتك المتعلقة باستخدام موقع المقاول الشعبي.',
    'الإشعارات القانونية والحقوق والاستخدام'
  ),
  [routes.conditions.route]: '/conditions',
  [routes.conditions.title]: 'شروط عامة',
  [routes.conditions.seo]: configRouteSeo(
    'شروط عامة',
    'الغرض من هذه الإشعارات القانونية هو إطلاعك على حقوقك والتزاماتك المتعلقة باستخدام موقع المقاول الشعبي.',
    'الشروط العامة وحقوق الملكية الفكرية وائتمانات الصور'
  ),
};
